export const GET_ERRORS = 'GET_ERRORS'
export const USER_FORMATION_LOADED = 'USER_FORMATION_LOADED'
export const USER_ONBOARDING = 'USER_ONBOARDING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const ADD_POST = 'ADD_POST'
export const DELETE_POST = 'DELETE_POST'
export const UPDATE = 'UPDATE'
export const EDIT_POST = 'EDIT_POST'
export const FETCH_ADMIN = 'FETCH_ADMIN'
export const FETCH_GROUPE = 'FETCH_GROUPE'
export const FETCH_PAGES = 'FETCH_PAGES'
export const PAGEID = 'PAGEID'

export const FETCH_FORMATION = 'FETCH_FORMATION'
export const ACTUAL_INDEX = 'ACTUAL_INDEX'

export const PROGRESSION = 'PROGRESSION'

export const DATES = 'DATES'

export const USER = 'USER'
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const PLAN = 'PLAN'
export const USERSGROUP = 'USERSGROUP'
export const PLAY_ADMIN = 'PLAY_ADMIN'

export const FETCH_PAGE = 'FETCH_PAGE'
export const SET_PAGE_RESULT = 'SET_PAGE_RESULT'
export const SET_PAGE_SCORE = 'SET_PAGE_SCORE'
export const SET_PAGE_USER = 'SET_PAGE_USER'

export const FETCH_COLORS = 'FETCH_COLORS'
export const SET_COLOR = 'SET_COLOR'

export const FETCH_EXERCICES = 'FETCH_EXERCICES'

export const FETCH_ADMINCLIENTS = 'FETCH_ADMINCLIENTS'

export const FETCH_ECOLES = 'FETCH_ECOLES'

export const ADMINGROUPES = 'ADMINGROUPES'

export const GLB_URL_APIS = process.env.REACT_APP_APIS_BACK || 'https://localhost:7443'
//export const GLB_URL_APIS = process.env.REACT_APP_APIS_BACK || 'https://thelabapis.risinguparis.com'
