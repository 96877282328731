import axios from 'axios'
import { GET_ERRORS, SET_CURRENT_USER, USER } from './types'
import { DESTROY_SESSION, GLB_URL_APIS } from './types'
import Cookies from "universal-cookie"

import { renewSocket } from "../contexts/SocketContext"

axios.defaults.withCredentials = true

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/user/register', userData)
    .then((res) => {
      history.push('/login')
    }) // re-direct to login on successful register
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
}
export const updateAccount = (name, poste, user = null) => (dispatch) => {
  if (user) {
    dispatch({
      type: USER,
      data: {
        ...user,
        name : name,
        poste : poste,
      },
    })
  }
}
export const onBoard =
  (userData, user = null) =>
  (dispatch) => {
    axios
      .post(GLB_URL_APIS + '/user/onBoardingAll', userData)
      .then((res) => {
        if (res && res.data && res.data.user) {
          dispatch({
            type: USER,
            data: {
              ...user,
              departement : res.data.user.departement,
              naissance : res.data.user.naissance,
              poste : res.data.user.poste,
              sexe : res.data.user.sexe,
              isOnboarded: true,
            },
          })
        }
      })
      .catch((err) => {})
  }

// Login
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/auth/login', userData)
    .then((res) => {
        if (res.data.message == "signin") {
          renewSocket()
          dispatch(setCurrentUser(res.data.user))

        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: {email : true, emailnotfound : true, password : true, passwordincorrect: true},
      })
    )
}
// Login
export const authStatus = () => (dispatch) => {
  axios
    .get(GLB_URL_APIS + '/auth/status')
    .then((res) => {
      if (res.data.message == "status") {
        renewSocket()
        dispatch(setCurrentUser(res.data.user))
      } else {
        //console.log("erreur authStatus")
        dispatch(logoutUser())
      }
    })
    .catch((err) => {
      dispatch(logoutUser())
    })
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  window.localStorage.risUpUser = JSON.stringify(decoded)
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

export const getCurrentUser = () => {
  if (window.localStorage.risUpUser) {
    let user = JSON.parse(window.localStorage.risUpUser)
    return user ? user : {}
  }
  return {}
}

// Log user out
export const logoutUser = () => (dispatch) => {
  const cookies = new Cookies()
  const cookieNames = Object.keys(cookies.getAll())
  cookieNames.forEach((cook) => {
    let searchString = process.env.REACT_APP_COOKIES_SESSION || "pp.connect.sid"
    if (cook.includes(searchString)) {
      let host = document.location.hostname
      let arr = host.split(".")
      if (arr.length === 1) {
        host = arr[0]
      } else {
        arr = arr.slice(-arr.length+1)
        host = "."+arr.join(".")
      }
      cookies.remove(cook, { path: '/', domain : host })
    }
  })
  renewSocket()

  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))

  dispatch({ type: DESTROY_SESSION })

  if (document?.location?.pathname?.includes("/admin/")) {
    if (document?.location?.origin) {
      window.location.href = document?.location?.origin
    }

    //window.location.href = './login'
  }

  //window.location.href = './login'
}
