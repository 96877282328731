import React, { useState, createContext, useEffect, useContext} from "react"
import { connect } from 'react-redux'

export const AdminGroupesContext = createContext()

const AdminGroupesProvider = ({ auth, user, adminGroupes, children }) => {

  const [groupes, setGroupes] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [actGroupe, setActGroupe] = useState(null)
  const [actGroupeID, setActGroupeID] = useState(null)
  const [actPage, setActPage] = useState(null)

  let defaultPage = "home"
  const getGroupeAndPage = (url) => {
    url = url.replace('/admindashboard', '')
    url = url.replace('/admindashboard/', '')
    let split = url.split('/')
    split = split.filter((el) => el !== '')

    if (split.length === 0) {
      return { id : null, page : defaultPage }
    } else if (split.length === 1) {
      return { id : parseInt(split[0]), page : defaultPage }
    } else if (split.length === 2) {
      if ((split[1] === defaultPage) || (split[1] === "suivi") || (split[1] === "mapping") || (split[1] === "certification")) {
        return { id : parseInt(split[0]), page : split[1] }
      } else {
        return { id : parseInt(split[0]), page : defaultPage }
      }
    } else {
      return { id : null, page : defaultPage }
    }
  }

  useEffect(() => {
    let include = window.location.pathname.includes("/admindashboard")
    if (user.type === "adminclient") {
      if (loaded === false && auth.formationLoaded === true) {

        if (adminGroupes.groupes.length > 0&& adminGroupes.ecoles.length > 0) {
          let groupeAndPage = getGroupeAndPage(window.location.pathname)
          let finalID = null
          let finalPage = groupeAndPage.page
          if (groupeAndPage.id === null) { //on prend la première école
            finalID = adminGroupes?.ecoles[0].ecoleID
          } else {
            let find = adminGroupes?.ecoles?.find((el) => el.ecoleID === groupeAndPage.id)
            if (find) {
              finalID = find.ecoleID
            } else {
              finalID = adminGroupes?.ecoles[0].ecoleID
            }
          }

          let findEcole = adminGroupes?.ecoles?.find((el) => el.ecoleID === finalID)
          let findAllGroupes = [...adminGroupes?.groupes].filter((el) => {
            if (el.ecoleID === findEcole.ecoleID) { return true } else { return false }
          })
          findAllGroupes = [...new Set(findAllGroupes)].sort((a, b) => {
            let textA = ""
            if (a.diplome) { textA = a.diplome } else { textA = a.name }
            let textB = ""
            if (b.diplome) { textB = b.diplome } else { textB = b.name }
            return textA.localeCompare(textB)
          })
          findEcole.groupes = findAllGroupes
          //on s'occupe des questions icebreaker
          let anneesReps = []
          let diplomeReps = []
          let secteursReps = []
          findEcole.groupes.forEach((g) => {
            if (g?.icebreakerQuestions?.annees === true) {
              g?.icebreakerQuestions?.anneesReps?.forEach((rep) => {
                anneesReps.push(rep)
              })
            }
            if (g?.icebreakerQuestions?.diplome === true) {
              g?.icebreakerQuestions?.diplomeReps?.forEach((rep) => {
                diplomeReps.push(rep)
              })
            }
            if (g?.icebreakerQuestions?.secteurs === true) {
              g?.icebreakerQuestions?.secteursReps?.forEach((rep) => {
                secteursReps.push(rep)
              })
            }
          })
          anneesReps = [...new Set(anneesReps)];
          diplomeReps = [...new Set(diplomeReps)];
          secteursReps = [...new Set(secteursReps)];
          findEcole.icebreakerQuestions = {
            annees : anneesReps.length > 0 ? true : false,
            anneesReps : anneesReps,
            diplome : diplomeReps.length > 0 ? true : false,
            diplomeReps : diplomeReps,
            secteurs : secteursReps.length > 0 ? true : false,
            secteursReps : secteursReps,
          }
          setGroupes(adminGroupes?.ecoles)
          setActGroupeID(finalID)
          setActGroupe(findEcole)


          if (finalPage === "suivi" && user.plan.suivi !== true) { finalPage = defaultPage }
          if (finalPage === "mapping" && user.plan.mapping !== true) { finalPage = defaultPage }
          if (finalPage === "certification" && user.plan.certif !== true) { finalPage = defaultPage }
          setActPage(finalPage)

          setLoaded(true)

          if (include) {
            let finalURL = window.location.origin+'/admindashboard/'+finalID+'/'+finalPage
            window.history.replaceState(null, null, finalURL)
          }

        } else {
          console.log("no groupes")
          let finalURL = window.location.origin+'/admindashboard/'
          window.history.replaceState(null, null, finalURL)
          setLoaded(true)
        }
      }
    }
  }, [auth.formationLoaded, adminGroupes, window.location.pathname])


  const updateActGroupe = (id, page) => {
    if (adminGroupes.groupes.length > 0 && adminGroupes.ecoles.length > 0) {
      let groupeAndPage = getGroupeAndPage(window.location.pathname)
      let findEcole = adminGroupes?.ecoles?.find((el) => el.ecoleID === id)
      let findAllGroupes = [...adminGroupes?.groupes].filter((el) => {
        if (el.ecoleID === findEcole.ecoleID) { return true } else { return false }
      })
      findAllGroupes = [...new Set(findAllGroupes)].sort((a, b) => {
        let textA = ""
        if (a.diplome) { textA = a.diplome } else { textA = a.name }
        let textB = ""
        if (b.diplome) { textB = b.diplome } else { textB = b.name }
        return textA.localeCompare(textB)
      })
      findEcole.groupes = findAllGroupes
      //on s'occupe des questions icebreaker
      let anneesReps = []
      let diplomeReps = []
      let secteursReps = []
      findEcole.groupes.forEach((g) => {
        if (g?.icebreakerQuestions?.annees === true) {
          g?.icebreakerQuestions?.anneesReps?.forEach((rep) => {
            anneesReps.push(rep)
          })
        }
        if (g?.icebreakerQuestions?.diplome === true) {
          g?.icebreakerQuestions?.diplomeReps?.forEach((rep) => {
            diplomeReps.push(rep)
          })
        }
        if (g?.icebreakerQuestions?.secteurs === true) {
          g?.icebreakerQuestions?.secteursReps?.forEach((rep) => {
            secteursReps.push(rep)
          })
        }
      })
      anneesReps = [...new Set(anneesReps)];
      diplomeReps = [...new Set(diplomeReps)];
      secteursReps = [...new Set(secteursReps)];
      findEcole.icebreakerQuestions = {
        annees : anneesReps.length > 0 ? true : false,
        anneesReps : anneesReps,
        diplome : diplomeReps.length > 0 ? true : false,
        diplomeReps : diplomeReps,
        secteurs : secteursReps.length > 0 ? true : false,
        secteursReps : secteursReps,
      }
      if (findEcole) {
        groupeAndPage.id = id
        setActGroupeID(id)
        setActGroupe(findEcole)
      } else {
        let findAllGroupes = [...adminGroupes?.groupes].filter((el) => {
          if (el.ecoleID === adminGroupes?.ecoles[0].ecoleID) { return true } else { return false }
        })
        findAllGroupes = [...new Set(findAllGroupes)].sort((a, b) => {
          let textA = ""
          if (a.diplome) { textA = a.diplome } else { textA = a.name }
          let textB = ""
          if (b.diplome) { textB = b.diplome } else { textB = b.name }
          return textA.localeCompare(textB)
        })
        let actGroupe = {...adminGroupes?.ecoles[0]}
        actGroupe.groupes = findAllGroupes
        groupeAndPage.id = adminGroupes?.ecoles[0].ecoleID
        setActGroupeID(adminGroupes?.ecoles[0].ecoleID)
        setActGroupe(actGroupe)
      }

      if (page) {
        groupeAndPage.page = page
        setActPage(groupeAndPage.page)
      }

      let finalURL = window.location.origin+'/admindashboard/'+groupeAndPage.id+'/'+groupeAndPage.page
      window.history.replaceState(null, null, finalURL)
    }
  }

  return (
    <AdminGroupesContext.Provider
      value={{
        loaded : loaded,
        groupes : groupes,
        actGroupeID : actGroupeID,
        actGroupe : actGroupe,
        actPage : actPage,
        updateActGroupe : updateActGroupe,
      }}
    >
      {children}
    </AdminGroupesContext.Provider>
  )
}

export default AdminGroupesContext

const mapStateToProps = (state) => {
  return {
    auth : state.auth,
    user : state.user,
    adminGroupes : state.adminGroupes,
  }
}

const AdminGroupesProvider2 = connect(
  mapStateToProps
)(AdminGroupesProvider)

export { AdminGroupesProvider2 }
